import { Component, OnInit } from '@angular/core';
import { CartService } from '../cart/cart.service';
import { LoaderService } from '../loader/loader.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  mainmenu:any;
  itmcnt;
  company: any;
  navClass = "navbar navbar-expand-lg navbar-dark bg-primary fixed-top";

  constructor(public cartService: CartService,
              public loaderService: LoaderService) {
                this.loaderService.cartCount.subscribe ( data => {
                  this.itmcnt = data;
                })
               }
  ngOnInit(): void {
    //this.itmcnt=this.cartService.items.length;
    this.company= {name:'Zircar Refractories Ltd.', tagline:'a cutting edge technology'};
    this.mainmenu=[
      {name: 'Home', 'hreflink': '#'},
      {name: 'Address Types', 'hreflink': '../addresstypes'},
      {name: 'Product Groups', 'hreflink': '../productgroups'},
      {name: 'header', 'hreflink': '../header'},
      {name: 'Contact us', 'hreflink': '#'}
    ];
   }
}
