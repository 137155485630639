import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  cards:any;
  constructor() { }

  ngOnInit(): void {
    this.cards = [
      {image: "/assets/prod1.jpg", alt: "First Slide", routerlink: "product/1", id: "1", title: "First Item", amount:"120", desc:"First item description"},
      {image: "/assets/prod1.jpg", alt: "Second Slide", routerlink: "product/2", id: "2",title: "Second Item",amount:"210", desc:"Second item description"},
      {image: "/assets/prod1.jpg", alt: "Third Slide",  routerlink: "product/3", id: "3", title: "Third Item",amount:"85", desc:"Third item description"},
      {image: "/assets/prod1.jpg", alt: "Fourth Slide",  routerlink: "product/4", id: "4", title: "Fourth Item",amount:"248", desc:"Fourth item description"},
      {image: "/assets/prod1.jpg", alt: "Fifth Slide",  routerlink: "product/5", id: "5", title: "Fifth Item", amount:"10", desc:"Fifth item description"},
      {image: "/assets/prod1.jpg", alt: "Sixth Slide",  routerlink: "product/6", id: "6", title: "Sixth Item",amount:"50", desc:"Sixth item description"},
      {image: "/assets/prod1.jpg", alt: "Seventh Slide",  routerlink: "product/7", id: "7", title: "Seventh Item",amount:"585", desc:"Seventh item description"},
      {image: "/assets/prod1.jpg", alt: "Eighth Slide",  routerlink: "product/8", id: "8", title: "Eighth Item",amount:"246", desc:"Eighth item description"},
      {image: "/assets/prod1.jpg", alt: "Ninth Slide",  routerlink: "product/9", id: "9", title: "Ninth Item",amount:"985", desc:"Ninth item description"},
    ];
  }
}
