import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProductskuserviceService {
  constructor(private httpClient: HttpClient) { }
  HttpUploadOptions = {
    headers: new HttpHeaders({ Accept: 'application/json' }),
  };
  
  getProductSKUs() {
    return this.httpClient.get(environment.apiURL + 'productsku');
  }

  getProductSKUswithproductid(productid) {
    return this.httpClient.get(
      environment.apiURL + 'productsku/indexwithproduct/' + 'productid'
    );
  }

  getProductSKU(ProductSKUId) {
    return this.httpClient.get(
      environment.apiURL + 'productsku/' + ProductSKUId
    );
  }
  getNewId(ProductId) {
    return this.httpClient.get(
      environment.apiURL + 'productsku/newid/' + ProductId
    );
  }

  createProductSKU(ProductSKUData) {
    return this.httpClient.post(
      environment.apiURL + 'productsku',
      ProductSKUData
    );
  }
  deleteProductSKU(ProductSKUId) {
    return this.httpClient.delete(
      environment.apiURL + 'productsku/' + ProductSKUId
    );
  }

  deleteProductSKUwithProductid(ProductId) {
    return this.httpClient.delete(
      environment.apiURL + 'productsku/destroywithProductId/' + ProductId
    );
  }

  editProductSKU(skuid,skuData) {
    return this.httpClient.put(environment.apiURL + 'productsku/' + skuid, skuData, this.HttpUploadOptions );
  }

}
