
<div class="py-3 bg-success text-center" style="color:black;font-weight: 600;" *ngIf="update">  
  There is an update for your application. Kindly <a href="./">Refresh/ Restart</a>  your App for applying updates.
</div>

<nav class="navbar navbar-expand-lg navbar-light" style="background-color:rgb(0, 119, 255);color:white;margin-top: -15px;">

  <button *ngIf="tokentype=='adminAuthToken'" mat-icon-button (click)="snav.toggle()">
    <mat-icon>menu</mat-icon>
  </button>
    <img routerLink="/products" data-toggle="collapse" class="navbar-nav navbar-toggler" loading="lazy" style="cursor: pointer;width:100px" src="../assets/icons/NETTIGO.svg" alt="GJ02Kart" /> 

  <ul data-toggle="collapse" class="navbar-nav navbar-toggler" style="font-size: small;">    
    <li>      
      <button *ngIf=(logeduser) class="btn btn-outline-warning"> {{logeduser}} ▾</button>
        <ul class="dropdown">
          <li><a routerLink="./users/profile">Profile</a></li>
          <li><a routerLink="./placeorders/orders">Orders</a></li>
          <li><a routerLink="./users/logout">Logout</a></li>
          <li><button style="color:white"  data-toggle="collapse" class="navbar-toggler" mat-stroked-button routerLink="/cart">
            <mat-icon matBadge={{itmcnt}} matBadgeColor="warn" class="my-2 my-sm-0" matBadgeSize="small">shopping_cart</mat-icon> Cart
          </button></li>
        </ul>
    </li>
</ul>
<!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
  <span class="navbar-toggler-icon"></span>
</button> -->

&nbsp; <button data-toggle="collapse" class="navbar-toggler mr-sm-2" *ngIf=(!logeduser) mat-flat-button color="primary" routerLink="/users/login"> Login </button>
&nbsp;
<!-- <button style="color:white"  data-toggle="collapse" class="navbar-toggler" mat-stroked-button routerLink="/cart">
  <mat-icon matBadge={{itmcnt}} matBadgeColor="warn" class="my-2 my-sm-0" matBadgeSize="small">shopping_cart</mat-icon> Cart
</button> -->

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <button mat-icon-button routerLink="/products">
       <!-- <img loading="lazy" style="cursor: pointer;width:200px" src="../assets/icons/NETTIGO.svg" alt="GJ02Kart" />  -->
      
    </button>
    
    <ul class="navbar-nav mr-auto">

    </ul>
    <ul class="navbar-nav">
         <li>
          <button *ngIf=(logeduser) class="btn btn-outline-warning" >  {{logeduser}} ▾</button>
             <ul class="dropdown">
               <li>
                 <a routerLink="./users/profile">Your Account</a>                                 
                </li>
           
               <li>
                 <a routerLink="./placeorders/orders">Your Orders</a>
          
                </li>
               <li><a routerLink="./users/logout">Logout</a></li>
        
             </ul>
         </li>
     </ul> &nbsp;&nbsp;
      <button *ngIf=(!logeduser) mat-flat-button color="primary" class="mr-sm-2" routerLink="/users/login">Login</button>
      <button mat-stroked-button routerLink="/cart">
        <mat-icon matBadge={{itmcnt}} matBadgeColor="warn" class="my-2 my-sm-0" matBadgeSize="small">shopping_cart</mat-icon> Cart
      </button>
  </div>
</nav>

  <mat-sidenav-container class="example-sidenav">
    <mat-sidenav  #snav (click)="snav.toggle()" class="mat-list"  mode="over" [autoFocus]="true">
      <mat-nav-list >
        <a  mat-list-item routerLink="{{nav.hreflink}}" *ngFor="let nav of mastermenu">{{nav.name}}</a>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content class="example-sidenav-content">
      <div class="container-fluid">
        <div class="row" >         
          <div class="col-12 col-sm-2 col-lg-2 col-md-2 mt-3 hideonmobile">
            <div class="sticky-top">
             <!--  <app-leftnav></app-leftnav> -->
             <router-outlet name="sb"></router-outlet>
            </div>            
          </div>
          <div class="col-12 col-sm-2 col-lg-10 col-md-10 px-0"  #mainContent>
            <router-outlet (activate)="onActivate($event)"></router-outlet>
          </div>
         
        </div> 
        <div class="row pt-3">
          <div class="col">
             
          </div>
        </div>        
      </div>
   
    </mat-sidenav-content>    
  </mat-sidenav-container> 
  
  <div class=""> <app-footer></app-footer></div> 
  <app-scroll-to-top></app-scroll-to-top>
  
 