// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be` found in `angular.json`.

export const environment = {
  production: false,
  razorpay_api_key: 'rzp_test_fKUeSCNvZSuUST',
  razorpay_key_secret: 'J0Q8KdBKQjhvE1upIn0GfbE2',
  razorpay_name: 'Nettigo Technology',
  razorpay_description: 'Software Company',
  razorpay_image: 'https://nettigo.in/images/onlylogo.png',
  webSocketURL: "wss://storewebsocket.nettigo.in",
  otp2factor: 'https://2factor.in/API/V1/eee87a90-411f-11ea-9fa5-0200cd936042/SMS/',
  //Local API URL
   //apiURL: 'http://localhost:8000/api/',
   //apiURL1: 'http://localhost:8000/api/app/',


  //Live API URL
  razorpayorderapi: "https://api.razorpay.com/v1/orders",
   apiURL: 'https://storeapi.nettigo.in/api/',
   apiURL1: 'https://storeapi.nettigo.in/public/api/app/', 
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
