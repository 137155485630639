import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  /*
  httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
  }  */
  HttpUploadOptions = {
    headers: new HttpHeaders({
      Accept: 'application/json',
      'Access-Control-Allow-Origin': 'always'
    }),
  };

  constructor(private httpClient: HttpClient) { }

  getProducts(noofrecords = null) {
    return this.httpClient.get(environment.apiURL + 'product' + "/" + noofrecords);
  }

  getActiveProducts(Active, noofrecords = null) {
    return this.httpClient.get(
      environment.apiURL + 'product/indexactive/' + Active + "/" + noofrecords
    );
  }

  getProductswithGroup(GroupId, noofrecords = null) {
    return this.httpClient.get(
      environment.apiURL + 'product/indexwithgroup/' + GroupId + "/" + noofrecords
    );
  }

  getProductswithGroupwopid(GroupId, ProductId, noofrecords = null) {
    return this.httpClient.get(
      environment.apiURL + 'product/indexwithgroupwopid/' + GroupId + '/' + ProductId + "/" + noofrecords
    );
  }

  searchProducts(searchTerm, noofrecords = null) {
    return this.httpClient.get(
      environment.apiURL + 'product/search/' + searchTerm + "/" + noofrecords
    );
  }

  getProduct(ProductId) {
    return this.httpClient.get(environment.apiURL + 'product/' + ProductId);
  }

  createProduct(productData) {
    return this.httpClient.post(
      environment.apiURL + 'product',
      productData,
      this.HttpUploadOptions
    );
  }
  deleteProduct(ProductId) {
    return this.httpClient.delete(environment.apiURL + 'product/' + ProductId);
  }

  getProductImage(id) {
    return this.httpClient.get(environment.apiURL + 'getimage/' + id);
  }

  updateProduct(productid: any, productData: any) {
    console.log(productData);
    return this.httpClient.put(environment.apiURL + "product/" + productid, productData, this.HttpUploadOptions);
  }


}
