<!-- <div id="divElement" *ngIf="loaderService.isloading | async" > </div> -->
<button class="plsmns" *ngIf="item.qty==1" color="primary" (click)="openRemoveItem(cartindex,item)" title="Remove Item">
    <i class="fas fa-trash-alt"></i>
</button>

<button class="plsmns" *ngIf="item.qty!=1" color="primary" (click)="updateCartQtyminus1(qty.value,cartindex,item)" title="Decrease quantity">
  <i class="fas fa-minus"></i> 
</button>

<input #qty type="number" style="text-align: center;" [value]="item.qty" (blur)="updateCartQty(qty.value,cartindex,item)" max=99 min="1">

<button class="plsmns" color="primary" (click)="updateCartQtyplus1(qty.value,cartindex,item)" title="Increase quantity">
  <i class="fas fa-plus"></i> 
</button>

