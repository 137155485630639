import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserscartService {
  HttpUploadOptions = {
    headers: new HttpHeaders({ Accept: 'application/json' }),
  };

  constructor(private httpClient: HttpClient) {}

  getCart(id) {
    return this.httpClient.get(environment.apiURL + 'usercart/' + id);
  }

  getUsersCart(UserId) {
    return this.httpClient.get(
      environment.apiURL + 'usercart/indexwithuser/' + UserId
    );
  }

  countUsersCart(UserId) {
    return this.httpClient.get(
      environment.apiURL + 'usercart/cartCountuserwise/' + UserId
    );
  }

  createUsersCart(cartData) {
    return this.httpClient.post(environment.apiURL + 'usercart', cartData);
  }

  deleteUsersCart(cartId) {
    return this.httpClient.delete(environment.apiURL + 'usercart/' + cartId);
  }

  deleteUsersCartwithGuid(arrayguid) {
    return this.httpClient.delete(
      environment.apiURL + 'usercart/removewithguid/' + arrayguid
    );
  }

  editUsersCart(cartId, cartData) {
    return this.httpClient.put(
      environment.apiURL + 'usercart/' + cartId,
      cartData,
      this.HttpUploadOptions
    );
  }
  editUsersCartwithGuid(arrayguid, cartData) {
    return this.httpClient.put(
      environment.apiURL + 'usercart/updatewithguid/' + arrayguid,
      cartData,
      this.HttpUploadOptions
    );
  }
}
