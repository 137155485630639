import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CartComponent } from './cart/cart/cart.component';
import { InternalServerComponent } from './error-pages/internal-server/internal-server.component';
import { NotFoundComponent } from './error-pages/not-found/not-found.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { LeftnavComponent } from './leftnav/leftnav.component';
import { LeftothernavComponent } from './leftothernav/leftothernav.component';
import { LoginguardGuard } from './loginguard.guard';
import { NotfoundComponent } from './notfound/notfound.component';



const routes: Routes = [
  { path: '', redirectTo: '/products', pathMatch: 'full' },

  {
    path: '',
    component: LeftnavComponent,
    outlet: "sb"
  },

  {
    path: 'nv', component: LeftothernavComponent,
    outlet: "sb"
  },

  { path: 'home', component: HomeComponent },
  { path: '404', component: NotFoundComponent },
  { path: '500', component: InternalServerComponent },
  { path: 'products', loadChildren: () => import('./products/products.module').then((m) => m.ProductsModule) },
  { path: 'cart', component: CartComponent },
  { path: 'header', component: HeaderComponent },
  { path: 'productgroups', loadChildren: () => import('./productgroups/productgroups.module').then((m) => m.ProductgroupsModule), },
  { path: 'addresstypes', loadChildren: () => import('./addresstypes/addresstypes.module').then((m) => m.AddresstypesModule), },
  { path: 'users', loadChildren: () => import('./users/users.module').then((m) => m.UsersModule), },
  { path: 'hsncodes', loadChildren: () => import('./hsncodes/hsncodes.module').then((m) => m.HsncodesModule), },
  { path: 'addresses', loadChildren: () => import('./addresses/addresses.module').then((m) => m.AddressesModule), },
  { path: 'placeorders', loadChildren: () => import('./placeorders/placeorders.module').then((m) => m.PlaceordersModule), },
  { path: 'userscart', loadChildren: () => import('./userscart/userscart.module').then((m) => m.UserscartModule), },
  { path: 'attributes', loadChildren: () => import('./attributes/attributes.module').then((m) => m.AttributesModule), },
  { path: 'attributegroups', loadChildren: () => import('./attributegroups/attributegroups.module').then((m) => m.AttributegroupsModule), },
  { path: 'productattributes', loadChildren: () => import('./productattributes/productattributes.module').then((m) => m.ProductattributesModule), },
  { path: 'productgroupattributes', loadChildren: () => import('./productgroupattributes/productgroupattributes.module').then((m) => m.ProductgroupattributesModule), },
  { path: 'productoptions', loadChildren: () => import('./productoptions/productoptions.module').then((m) => m.ProductoptionsModule), },
  { path: 'policy', loadChildren: () => import('./policy/policy.module').then((m) => m.PolicyModule), },
  { path: 'help', loadChildren: () => import('./help/help.module').then((m) => m.HelpModule), },
  { path: 'documenttypes', loadChildren: () => import('./documenttypes/documenttypes.module').then((m) => m.DocumenttypesModule), },
  { path: 'discountmasters', loadChildren: () => import('./discountmasters/discountmasters.module').then(m => m.DiscountmastersModule) },
  { path: 'discountsubtypes', loadChildren: () => import('./discountsubtypes/discountsubtypes.module').then(m => m.DiscountsubtypesModule) },
  //{path:'', component:HomeComponent}
  { path: '**', component: NotfoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {
  hidden = false;
  hideCard() {
    this.hidden = true;
  }
}
