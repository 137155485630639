import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FooterComponent } from './footer/footer.component';
import { SidenavComponent } from './sidenav/SidenavComponent';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './loader/interceptor.service';
import { CartComponent } from './cart/cart/cart.component';
import { AuthInterceptor } from './auth.interceptor';
import { DatePipe } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { InternalServerComponent } from './error-pages/internal-server/internal-server.component';
import { NotFoundComponent } from './error-pages/not-found/not-found.component';
import { ScrollToTopComponent } from './scroll-to-top/scroll-to-top.component';
import { LeftnavComponent } from './leftnav/leftnav.component';
import { CartSummaryComponent } from './cart/cart-summary/cart-summary.component';
import { LeftothernavComponent } from './leftothernav/leftothernav.component';
import { RemovecartitemComponent } from './cart/removecartitem/removecartitem.component';
import { UpdatecartbtnComponent } from './cart/updatecartbtn/updatecartbtn.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    SidenavComponent,
    HomeComponent,
    NotfoundComponent,
    CartComponent,
    InternalServerComponent,
    NotFoundComponent,
    ScrollToTopComponent,
    LeftnavComponent,
    CartSummaryComponent,
    LeftothernavComponent,
    RemovecartitemComponent,
    UpdatecartbtnComponent
  ],
  exports: [CartComponent, LeftnavComponent, CartSummaryComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  /*
  entryComponents:[AddAddressesComponent], */
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
