
import { Component, OnInit } from '@angular/core';
import { ProductgroupService } from '../productgroups/productgroup.service';
import { ErrorHandlerService } from '../services/error-handler.service';

export interface type{
  parentGroup:any;
  text:string;
}

@Component({
  selector: 'app-leftnav',
  templateUrl: './leftnav.component.html',
  styleUrls: ['./leftnav.component.scss'],
})

export class LeftnavComponent implements OnInit {
  productgroups: any;
  parentProductGroup: any;
  public errorMessage: string = '';

  test: any;
  public mySentences:type[];

  constructor(private productgroupService: ProductgroupService,
    private errorHandler: ErrorHandlerService) {
    this.productgroupService.getchildrenRecursive().subscribe(data => {
      this.productgroups = data;
    
    }, (error) => {
      this.errorHandler.handleError(error);
      this.errorMessage = this.errorHandler.errorMessage;
    })
  }



  ngOnInit(): void { }  
}
