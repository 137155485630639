
<div *ngIf="productgroups?.length">
    <p class="leftnavheader text-center">Product Categories</p> 
    <nav *ngFor="let prdgroups of productgroups">
        <!-- If group has no more group -->
        <a *ngIf="prdgroups.products.length>0" routerLink="./products/group/{{prdgroups.id}}" routerLinkActive="active">{{prdgroups.name}}  - ({{prdgroups.products.length}})</a>               
        <!-- If group has more group -->
        <a *ngIf="prdgroups.children_recursive.length>0" routerLink="./products/group/{{prdgroups.id}}"  routerLinkActive="active">{{prdgroups.name}}  - ({{prdgroups.children_recursive.length}})</a>        
           
        <!-- Display child group -->
        <nav *ngFor="let prntPrdgroups of prdgroups.children_recursive">                
             <a style="margin:10px 0px 0px 10px; background-color: azure;" routerLink="./products/group/{{prntPrdgroups.id}}"  routerLinkActive="active">{{prntPrdgroups.name}} - ({{prntPrdgroups.products.length}})</a>
         </nav>       
    </nav>       
</div>
