import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-leftothernav',
  templateUrl: './leftothernav.component.html',
  styleUrls: ['./leftothernav.component.scss']
})
export class LeftothernavComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
