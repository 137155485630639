import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  items = [];
  totalVal;
  totalValwithDisc;
  totaldiscount;

  addToCart(product) {
    this.items.push(product);
  }

  getItems() {
    return this.items;
  }

  clearCart() {
    this.items = [];
    return this.items;
  }

  deleteItem(index) {
    this.items.splice(index, 1);
  }

  calculateTotal() {
    this.totalVal = 0;
    this.totalValwithDisc = 0;
    this.totaldiscount = 0;

    var cnt = 0;
    let discountedprice = 0;
    let discount = 0;
    while (cnt < this.items.length) {

      if (this.items[cnt].sku) {
        discount = parseInt(this.items[cnt].sku["compare_at_price"]) - parseInt(this.items[cnt].sku["price"]);
        discountedprice = parseInt(this.items[cnt].sku["price"]);

        this.totalValwithDisc = parseInt(this.totalValwithDisc) + (discountedprice * parseInt(this.items[cnt]["qty"]));
        this.totalVal = parseInt(this.totalVal) + (parseInt(this.items[cnt].sku["compare_at_price"]) * parseInt(this.items[cnt]["qty"]));
        this.totaldiscount = this.totaldiscount + (discount * parseInt(this.items[cnt]["qty"]));
        cnt = cnt + 1
      }
      else {
        discount = parseInt(this.items[cnt].product["sale_rate"]) * parseInt(this.items[cnt].product["discount"]) / 100;
        discountedprice = parseInt(this.items[cnt].product["sale_rate"]) - discount;

        this.totalValwithDisc = parseInt(this.totalValwithDisc) + (discountedprice * parseInt(this.items[cnt]["qty"]));
        this.totalVal = parseInt(this.totalVal) + (parseInt(this.items[cnt].product["sale_rate"]) * parseInt(this.items[cnt]["qty"]));
        this.totaldiscount = this.totaldiscount + (discount * parseInt(this.items[cnt]["qty"]));
        cnt = cnt + 1
      }

    }

    return ({ 'TotalVal': this.totalVal, 'TotalwithDisc': this.totalValwithDisc, 'TotalDiscount': this.totaldiscount });
  }
}
