import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProductgroupService {
  constructor(private httpClient: HttpClient) {}

  getProductGroups() {
    return this.httpClient.get(environment.apiURL + 'productgroup');
  }

  getProductGroupsAll() {
    return this.httpClient.get(
      environment.apiURL + 'productgroupall'
    );
  }

  getchildrenRecursive() {
    return this.httpClient.get(
      environment.apiURL + 'childrenRecursive'
    );
  }
  getPrimaryproductgroup() {
    return this.httpClient.get(
      environment.apiURL + 'parentgroupproductgroup'
    );
  }
  getParentgroupproductgroup(ParentGrouId) {
    return this.httpClient.get(
      environment.apiURL + 'parentgroupproductgroup/' + ParentGrouId
    );
  }
  

  getProductGroup(ProductGroupId) {
    return this.httpClient.get(
      environment.apiURL + 'productgroup/' + ProductGroupId
    );
  }

  createProductGroup(productgroupData) {
    return this.httpClient.post(
      environment.apiURL + 'productgroup',
      productgroupData
    );
  }
  deleteProductGroup(ProductGroupId) {
    return this.httpClient.delete(
      environment.apiURL + 'productgroup/' + ProductGroupId
    );
  }
}
