<h3 class="my-4 text-center"><a style="text-decoration: none;" routerLink="/products">{{mainCat}}</a></h3>

<div class="list-group" *ngFor="let sidenav of sidenavs">
  <a style="text-decoration: none;" [routerLink]="'products/group/' + sidenav.id" class="list-group-item list-group-item-light btn btn-sm btn-success">{{sidenav.name}}</a>
  <!-- <a type="button" style="text-decoration: none;" [routerLink]="'products/group/' + sidenav.id" class="list-group-item list-group-item-success">{{sidenav.name}}</a> -->
</div>

<!-- <div class="list-group">
  <button type="button" class="list-group-item list-group-item-action active">
    Cras justo odio
  </button>
</div> -->

