  <!-- Footer -->
  <footer class="py-4" style="background-color:rgb(4, 30, 48)">
    <div class="container-fluid">
      <div class="row" style="color:white">
        <div class="col-md-2 col-sm-6 col-6 small">          
          <ul class="nav flex-column">
            <li class="nav-item">
              <a class="nav-link" style="color:gray">ABOUT</a>
            </li>
            <li class="nav-item">
              <a class="nav-link active" href="#">Contact Us</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">About Us</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Careers</a>
            </li>
            <li class="nav-item">
              <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">News</a>
            </li>
          </ul>
        </div>
        <div class="col-md-2 col-sm-6 col-6 small">
          <ul class="nav flex-column">
            <li class="nav-item">
              <a class="nav-link" style="color:gray">HELP</a>
            </li>
            <li class="nav-item">
              <a class="nav-link active" routerLink="../../help/payments">Payments</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="../../help/shipping">Shipping</a>
            </li>
            <!-- <li class="nav-item">
              <a class="nav-link" href="#">Cancelation & Return</a>
            </li> -->
            <li class="nav-item">
              <a class="nav-link" routerLink="../../help/faq">FAQ</a>
            </li>
          </ul>
        </div>
        <div class="col-md-2 col-sm-6 col-6 small">
          <ul class="nav flex-column">
            <li class="nav-item">
              <a class="nav-link" style="color:gray">POLICY</a>
            </li>
            <li class="nav-item">
              <a class="nav-link active" routerLink="../../policy/returnpolicy">Return Policy</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="../../policy/termsofuse">Terms of Use</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="../../policy/security">Security</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="../../policy/privacy">Privacy</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Sietmap</a>
            </li>
            
          </ul>
        </div>
        <div class="col-md-2 col-sm-6 col-6 small">
          <ul class="nav flex-column">
          <li class="nav-item">
            <a class="nav-link" style="color:gray">SOCIAL</a>
          </li>
          <li class="nav-item">
            <a class="nav-link active" href="#">Facebook</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Twitter</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Youtube</a>
          </li>
         
        </ul>
      </div>
      <div class="col-md-2 col-sm-6 col-6 small vl">
        <ul class="nav flex-column">
          <li class="nav-item">
            <a class="nav-link" style="color:gray">Mail Us:</a>
          </li>
          <li class="nav-item">
            <p class="nav-link">
            Nettigo Technology <br>
            H/207, Shreeji Sharan Flat <br>
            Ucharapi Road <br>
            Mehsana - 384002 <br>
            Gujarat, India <br>          
          </li>
        </ul>
      </div>
      <div class="col-md-2 col-sm-6 col-6 small">
          <ul class="nav flex-column">
          <li class="nav-item">
            <a class="nav-link" style="color:gray">Registered Office Address:</a>
          </li>
          <li class="nav-item">
            <p class="nav-link">
            Nettigo Technology <br>
            H/207, Shreeji Sharan Flat <br>
            Ucharapi Road <br>
            Mehsana - 384002 <br>
            Gujarat, India <br>
            Telephone : <a href="tel:+919724318669" style="color:lightblue">+91 97243 18669</a> </p>
          </li>
        </ul>
      </div>

      </div>   
      
      <!--  -->
    </div>
    <hr class="new1">
    <div class="container-fluid">
    
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <p class="m-0 text-white"> &copy; {{currentYear | date:"yyyy"}} {{company}}</p>
      </div>
      <div class="col-md-6 col-sm-12">
        <img src="../../assets/icons/PaymentMethod.png" class="float-right" alt="">   
      </div>
    </div>
    </div>
    <!-- /.container -->
  </footer>
