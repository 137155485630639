import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from 'src/app/loader/loader.service';
import { LocalstorageserviceService } from 'src/app/localstorageservice.service';
import { ProductService } from 'src/app/products/product.service';

import { UserscartService } from 'src/app/userscart/userscart.service';
import { environment } from 'src/environments/environment';
import { CartService } from '../cart.service';
import { RemovecartitemComponent } from '../removecartitem/removecartitem.component';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})

export class CartComponent implements OnInit {
  loadthruapp;

  items;
  deliveryCharge = 0;

  cartSummary;
  userid;
  imagestr = environment.apiURL1;

  constructor(private cartService: CartService,
    private productService: ProductService,
    private activatedRoute: ActivatedRoute,
    private userCartService: UserscartService,
    public loaderService: LoaderService,
    private localstorageService: LocalstorageserviceService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,) {
    this.userid = this.localstorageService.get('userid');
    this.activatedRoute.params.subscribe(data => {
      this.loadthruapp = data.loadthruapp;
    })
  }

  //This function is used to update cart summary when update chind component - updatecartbutton component
  ParentFunction(data) {
    //console.warn(data);
    this.cartSummary = this.cartService.calculateTotal();
  }
  ClearCart() {
    this.cartService.clearCart();
    this.ngOnInit();
  }

  openRemoveItem(index, itm) {
    this.dialog.afterAllClosed.subscribe(data => { this.ngOnInit(); });

    this.dialog.open(RemovecartitemComponent, {
      width: '400px',
      data: { index: index, itm: itm },
    });

  }

  /*  removeItem(index, itm) {
 
     if (confirm("Are you sure you want to remove this item?")) {
       this.cartService.deleteItem(index);  ////Remove item from cart array
       this.userCartService.deleteUsersCartwithGuid(itm.arrayguid).subscribe(data => {
       });
       this.cartSummary = this.cartService.calculateTotal();
     }
   }
  */
  ngOnInit(): void {

    this.items = this.cartService.getItems();
    //console.log(this.items);
    //This is added here just intercept httpservice to update cart bedge value    
    /* this.productService.getProducts().subscribe(data => {
      this.items = this.cartService.getItems();
    }) */
    this.cartSummary = this.cartService.calculateTotal();
  }


}