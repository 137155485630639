import { ChangeDetectionStrategy, Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CartService } from './cart/cart.service';
import { LoaderService } from './loader/loader.service';
import { LocalstorageserviceService } from './localstorageservice.service';
import { UserscartService } from './userscart/userscart.service';
import { ProductskuserviceService } from './productoptions/productskuservice.service'
import { SwUpdate } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {

  @ViewChild("mainContent")
  private mainContentDiv!: ElementRef<HTMLElement>;

  title = 'myCart';
  mastermenu;

  itmcnt;
  company: any;

  logeduser;
  userid;
  showFiller = false;

  tokentype;
  cart;
  cartcount;

  deviceInfo = null;
  update: boolean = false;


  constructor(
    private updates: SwUpdate,
    public dialog: MatDialog,
    public cartService: CartService,
    public loaderService: LoaderService,
    private userCartService: UserscartService,
    private productskuserviceService: ProductskuserviceService,
    private localStorageServiceService: LocalstorageserviceService,
    public _snackBar: MatSnackBar,
    private deviceService: DeviceDetectorService,
    private readonly router: Router
  ) {

    //this.epicFunction();
    updates.available.subscribe((event) => {
      //updates.activateUpdate().then(() => document.location.reload());      
      this.update = true;
      this.updateMessge();
    });
    this.loaderService.cartCount.subscribe((data) => {
      this.itmcnt = data;
      //console.log(data);
      this.logeduser = this.localStorageServiceService.get('username');
    });
  }
  onActivate(_event: any): void {
    // Scrolling back to the top
    // Reference: https://stackoverflow.com/questions/48048299/angular-5-scroll-to-top-on-every-route-click/48048822
    if (this.mainContentDiv) {
      (this.mainContentDiv.nativeElement as HTMLElement).scrollTop = 0;
    }
  }

  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    console.log(this.deviceInfo);
    console.log(isMobile); // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    console.log(isTablet); // returns if the device us a tablet (iPad etc)
    console.log(isDesktopDevice); // returns if the app is running on a Desktop browser.
  }



  updateMessge() {
    this._snackBar.open('There is an update for your application. Kindly Refresh/Restart your App for applying updates.', 'Close',
      {
        duration: 3000,
        horizontalPosition: "center",
        verticalPosition: "top",
      });
  }
  ngOnInit(): void {

    this.userid = this.localStorageServiceService.get('userid');
    this.tokentype = this.localStorageServiceService.get('tokentype');

    this.userCartService.getUsersCart(this.userid).subscribe(async data => {
      this.cart = data;
      let i = 0;
      while (i < this.cart.length) {
        data[i].incart = true; //Add new property - incart to product object
        data[i].qty = data[i].qty; //Add new property - qty to product object
        var sku = await this.productskuserviceService.getProductSKU(data[i].sku_id).toPromise();
        data[i].sku = sku[0]; //Add new property - sku to product object
        this.cartService.addToCart(data[i]); // Load cart with new product object with qty and incart properties
        i++;
      }
      this.itmcnt = this.cart.length;
      //console.log(this.cartcount);      
    });

    this.company = {
      name: 'Nettigo Technology',
      tagline: 'a software company',
    };

    this.mastermenu = [
      { name: 'Home', hreflink: '../products' },
      { name: 'Address Types', hreflink: '../addresstypes' },
      { name: 'Addresses', hreflink: '../addresses' },
      { name: 'Product Groups', hreflink: '../productgroups' },
      { name: 'Add Product', hreflink: '../products/add/new' },
      { name: 'Product Options', hreflink: '../products/productlist' },
      { name: 'Discount', hreflink: '../discountmasters/add' },
      { name: 'HSN Code', hreflink: '../hsncodes' },
      { name: 'Attribute Groups', hreflink: '../attributegroups' },
      { name: 'Attributes', hreflink: '../attributes' },
      {
        name: 'Product Group Attributes',
        hreflink: '../productgroupattributes',
      },
      { name: 'Product Attributes', hreflink: '../productattributes' },
      /*   {name: 'header', 'hreflink': '../header'}, */
      { name: 'Contact us', hreflink: '/' },
    ];
  }
}
