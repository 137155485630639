import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from 'src/app/loader/loader.service';
import { LocalstorageserviceService } from 'src/app/localstorageservice.service';
import { ProductService } from 'src/app/products/product.service';

import { UserscartService } from 'src/app/userscart/userscart.service';
import { environment } from 'src/environments/environment';
import { CartService } from '../cart.service';
import { RemovecartitemComponent } from '../removecartitem/removecartitem.component';

@Component({
  selector: 'app-updatecartbtn',
  templateUrl: './updatecartbtn.component.html',
  styleUrls: ['./updatecartbtn.component.scss']
})
export class UpdatecartbtnComponent implements OnInit {

  @Input() item;
  @Input() cartindex;

  @Output() ParentFunction: EventEmitter<any> = new EventEmitter()
  loadthruapp;

  items;
  deliveryCharge = 0;

  cartSummary;
  userid;
  imagestr = environment.apiURL1;

  constructor(private cartService: CartService,
    private activatedRoute: ActivatedRoute,
    private userCartService: UserscartService,
    public loaderService: LoaderService,
    private localstorageService: LocalstorageserviceService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,) {
    this.userid = this.localstorageService.get('userid');
    this.activatedRoute.params.subscribe(data => {
      this.loadthruapp = data.loadthruapp;
    })
  }

  ClearCart() {
    this.cartService.clearCart();
    this.ngOnInit();
  }


  openRemoveItem(index, itm) {
    this.dialog.afterAllClosed.subscribe(data => { this.ngOnInit(); });

    this.dialog.open(RemovecartitemComponent, {
      width: '400px',
      data: { index: index, itm: itm },
    });

  }

  ngOnInit(): void {
    this.ParentFunction.emit("ChildToParent");
    this.items = this.cartService.getItems();

    this.cartSummary = this.cartService.calculateTotal();
  }

  updateCartQty(qty, index, item) {
    if (this.items[index].qty == 0 || this.items[index].qty == '') {
      this.items[index].qty = 1;
      //Update usercart (DB)
      const updateuserCart = {
        arrayguid: item.arrayguid,
        qty: 1
      }
      this.userCartService.editUsersCartwithGuid(item.arrayguid, updateuserCart).subscribe(data => { });
    }
    else {
      this.items[index].qty = qty;
      //Update usercart (DB)
      const updateuserCart = {
        arrayguid: item.arrayguid,
        qty: qty
      }
      this.userCartService.editUsersCartwithGuid(item.arrayguid, updateuserCart).subscribe(data => { });
    }
    this._snackBar.open("You have changed '" + item.product.name + "' QUANTITY to '" + this.items[index].qty + "'", 'Close', { duration: 2000 });
    this.cartSummary = this.cartService.calculateTotal();
    this.ParentFunction.emit("ChildToParent");
  }

  updateCartQtyplus1(qty, index, item) {
    this.items[index].qty = parseInt(qty) + 1;
    //Update usercart (DB)
    const updateuserCart = {
      arrayguid: item.arrayguid,
      qty: parseInt(qty) + 1
    }
    this.userCartService.editUsersCartwithGuid(item.arrayguid, updateuserCart).subscribe(data => { });
    this._snackBar.open("You have changed '" + item.product.name + "' QUANTITY to '" + this.items[index].qty + "'", 'Close', { duration: 2000 });
    this.cartSummary = this.cartService.calculateTotal();
    this.ParentFunction.emit("ChildToParent");
  }

  updateCartQtyminus1(qty, index, item) {
    if (this.items[index].qty != 1) {
      this.items[index].qty = parseInt(qty) - 1;
      //Update usercart (DB)
      const updateuserCart = {
        arrayguid: item.arrayguid,
        qty: parseInt(qty) - 1
      }
      this.userCartService.editUsersCartwithGuid(item.arrayguid, updateuserCart).subscribe(data => { });
      this._snackBar.open("You have changed '" + item.product.name + "' QUANTITY to '" + this.items[index].qty + "'", 'Close', { duration: 2000 });
    }
    this.cartSummary = this.cartService.calculateTotal();
    this.ParentFunction.emit("ChildToParent");
  }
}
