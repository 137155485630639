import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/loader/loader.service';

@Component({
  selector: 'app-cart-summary',
  templateUrl: './cart-summary.component.html',
  styleUrls: ['./cart-summary.component.scss']
})
export class CartSummaryComponent implements OnInit {

  @Input() cartSummary: string;
  @Input() items: string;

  constructor() { }

  ngOnInit(): void {

  }

}
