<div id="carouselExampleIndicators" class="carousel slide mb-2" data-ride="carousel">
  <ol class="carousel-indicators">
    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="6"></li>
  </ol>
  <div class="carousel-inner text-center" role="listbox">
    <div class="carousel-item active">
      <img class="cropped" width="100%" src="\assets\slide0_0.jpg" routerLink="../../users/login" alt="slide">
    </div>
    <div class="carousel-item fade">
      <img class="cropped" width="100%" src="\assets\slide1_1.jpg" alt="First slide">
    </div>
    <!-- <div class="carousel-item text-center">
      <img class="img-fluid" src="\assets\slide2_2.jpg" alt="Second slide">
    </div> -->
    <div class="carousel-item text-center fade">
      <img  class="cropped" width="100%" src="\assets\slide3_3.jpg" alt="Third slide">
    </div>
    <div class="carousel-item text-center fade">
      <img class="cropped" width="100%" src="\assets\slide4_4.jpg" alt="Fourth slide">
    </div>
    <div class="carousel-item text-center fade">
      <img class="cropped" width="100%" src="\assets\slide5_5.jpg" alt="Fifth slide">
    </div>
    <div class="carousel-item text-center fade">
      <img class="cropped" width="100%" src="\assets\slide6_6.jpg" alt="Sixth slide">
    </div>
    <div class="carousel-item text-center fade">
      <img class="cropped" width="100%" src="\assets\slide7_7.jpg" alt="Sixth slide">
    </div>
  </div>

  <a class="carousel-control-prev" href="#carouselExampleIndicators" data-slide="prev">
    <span class="carousel-control-prev-icon"></span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleIndicators" data-slide="next">
    <span class="carousel-control-next-icon"></span>
  </a>
</div>

        <!-- <div class="row" >
          <div class="col-lg-4 col-md-6 mb-4" *ngFor="let card of cards">
            <div class="card h-100">
              <a [routerLink]="card.routerlink"><img class="card-img-top" [src]="card.image" alt=""></a>
              <div class="card-body">
                <h4 class="card-title">
                  <a [routerLink]="card.routerlink">{{card.title}}</a>
                </h4>
                <h5>{{card.amount | currency:'INR'}}</h5>
                <p class="card-text">{{card.desc}}</p>
              </div>
              <div class="card-footer">
                <small class="text-muted">&#9733; &#9733; &#9733; &#9733; &#9734;</small>
              </div>
            </div>
          </div>

        </div> -->
        <!-- /.row -->

