<nav [ngClass]="navClass">
  <div class="container">

    <a class="navbar-brand" routerLink="/">
      {{company.name}}
      <span style="font-size: x-small;"> {{company.tagline}}</span>
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive"
      aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarResponsive">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item" *ngFor="let menu of mainmenu">
          <a class="nav-link" [routerLink]="menu.hreflink">{{menu.name}}
            <span class="sr-only">(current)</span>
          </a>
        </li>

        <li class="dropdown show">
          <a class=" nav-link dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            Products
          </a>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <a class="dropdown-item" routerLink="../products/add">Add New</a>
            <a class="dropdown-item" routerLink="../products">List Products</a>
          </div>
        </li>

        <li class="nav-item">
          <a routerLink="/cart" class="nav-link" matBadge={{itmcnt}} matBadgePosition="above after" matBadgeSize="small"
            matBadgeColor="accent">
            <span class="material-icons">shopping_cart</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
