import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CartService } from '../cart/cart.service';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor(private cartService: CartService) { }

  public isloading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public cartCount: BehaviorSubject<number> = new BehaviorSubject<number>(this.cartService.items.length);
}