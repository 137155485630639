import { Component, OnInit } from '@angular/core';
import { ProductgroupService } from '../productgroups/productgroup.service';


@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  sidenavs: any;
  mainCat = '';
  constructor(private productGroupService: ProductgroupService) { }

  ngOnInit(): void {
    this.mainCat = "Categories";
    this.productGroupService.getProductGroups().subscribe(data => {
      this.sidenavs = data;
    });
  }
}
