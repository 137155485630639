
<div id="divElement" *ngIf="loaderService.isloading | async" > </div>
<div *ngIf="(items) != '' " class="row">
  <div class="col-6">
    <div class="pb-3" style="font-weight: 600;">My Cart ({{items.length}})</div>
  </div>
  <!-- <div class="col-6 pb-2">
    <button mat-raised-button color="warn" (click)="ClearCart()">
      <mat-icon>remove_shopping_cart</mat-icon>Clear Cart
    </button>
  </div> -->
</div>
<div *ngIf="(items) == '' " class="text-center">
  <img style="height:200px; width:250px;" class="centered" src="assets/img/emptycart.png" alt="">
  <p class="mat-display-1">Your cart is empty</p>
  <small>Add items to it now.</small> <br>
  <button routerLink="../products" mat-raised-button color="primary">Shop Now</button>
  <div class="py-3"></div>
</div>

<div class="container-fluid">
<div class="row px-4" *ngIf="items.length!=0">
        <div class="card col-sm-8">
          <div class="card-body" *ngFor="let item of items; index as i">
            <div class="row">
              <div class="col-3 col-lg-3 pb-2">
                <img class="card-img-left"  routerLink="../products/view/{{item.product.id}}" style="margin: auto;cursor:pointer ;height: 4rem;width: 4rem" src="{{imagestr + item.product.image}}"
                  alt="item.name">
              </div>

              <div class="col-9 col-lg-6">
                <p>
                  <a style="text-decoration: none;" routerLink="../products/view/{{item.product.id}}">
                    {{item.product.name}} 
                    <small *ngIf="item.sku"> <br>{{item.sku.sku}}</small>
                  </a>
                    <br>
                    <small style="color:gray">{{item.product.desc1}}</small>
                </p>
                <h4 *ngIf="item.sku" class="card-title">
                  <b>{{(item.sku.price*item.qty) | currency:'INR': 'symbol' : '1.0-0'}}</b> &nbsp; <s><small>{{item.sku.compare_at_price*item.qty | currency:'INR': 'symbol' : '1.0-0'}}</small></s> &nbsp; <small style="color:green;font-weight: 600;"> {{(item.sku.compare_at_price - item.sku.price)*item.qty | currency:'INR': 'symbol' : '1.0-0'}}  off </small>
                </h4>
                <h4 *ngIf="!item.sku" class="card-title">
                  <b>{{(item.product.sale_rate - (item.product.sale_rate*item.product.discount)/100)*item.qty | currency:'INR': 'symbol' : '1.0-0'}}</b> &nbsp; <s><small>{{item.product.sale_rate*item.qty | currency:'INR': 'symbol' : '1.0-0'}}</small></s> &nbsp; <small style="color:green;font-weight: 600;"> {{(item.product.sale_rate*item.product.discount/100)*item.qty | currency:'INR': 'symbol' : '1.0-0'}}  off </small>
                </h4>
               <!--  <ul class="card-text">
                  <li>{{item.desc1}}</li>
                </ul> -->
              </div>
             <!--  <div class="col-3">
                
              </div>
              --> <div class=" col-lg-3 text-right">
                Delivery in 2 days
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-lg-4">
                <app-updatecartbtn (ParentFunction)="ParentFunction($event)"  [item]="item" [cartindex]="i"></app-updatecartbtn>
            </div>

              <div class="col-12 col-lg-8">
                <a mat-button>
                  SAVE FOR LATER
                </a>
                <a mat-button (click)="openRemoveItem(i,item)">
                  <mat-icon>remove_shopping_cart</mat-icon>REMOVE
                </a>
              </div>
            </div>
          
          </div>

        <div class="row pb-3" *ngIf="loadthruapp!='app'">
          <div class="col text-right">
                <button *ngIf="(items) != '' " routerLink="/placeorders"  mat-raised-button color="accent">PLACE ORDER</button>
          </div>
        </div>
        </div>
  <!-- Order Summary -->
  <div class="col-sm-4" *ngIf="loadthruapp!='app'">
    <app-cart-summary [cartSummary]="cartSummary" [items]="items" ></app-cart-summary>
</div>
</div>
</div>