<!-- <div id="divElement" *ngIf="loaderService.isloading | async" > </div> -->
<div *ngIf="(items) != '' " class="cart-item ">
    <mat-card>
      <p class="text-muted">PRICE DETAILS</p>
      <mat-divider></mat-divider>
      <!-- <div *ngIf="loaderService.isloading | async">
        <mat-progress-bar mode="indeterminate" color="decent"></mat-progress-bar>
     </div> -->

      <div class="row pt-2">
        <div class="col-sm-8">
          <p *ngIf="(items.length) > 1 ">Price ({{items.length}}) Items</p>
          <p *ngIf="(items.length) == 1 ">Price ({{items.length}}) Item</p>
        </div>
        <div class="col-sm-4 text-right">
          {{cartSummary.TotalVal | currency:'INR': 'symbol' : '1.0-0'}}
        </div>
      </div>

      <div class="row">
        <div class="col-sm-8">
          <p>Discount</p>
        </div>
        <div class="col-sm-4 text-right">
          {{cartSummary.TotalDiscount | currency:'INR': 'symbol' : '1.0-0'}}
        </div>
      </div>

      <div class="row">
        <div class="col-sm-8">
          <p>Delivery Charges</p>
        </div>
        <div style="color:green" *ngIf="deliveryCharge==0" class="col-sm-4 text-right">
          FREE
        </div>
        <div *ngIf="deliveryCharge!=0" class="col-sm-4 text-right">
          {{deliveryCharge | currency:'INR': 'symbol' : '1.0-0'}}
        </div>
      </div>

      <div class="row" style="font-weight: 600;">
        <mat-divider></mat-divider>
        <div class="col-sm-8 pt-2">
          <p>Total Amount</p>
        </div>
        <div class="col-sm-4 text-right pt-2">
          {{cartSummary.TotalwithDisc | currency:'INR': 'symbol' : '1.0-0'}}
        </div>
      </div>

      <p style="color:green; font-weight: 600;">
        You will save {{totaldiscount | currency:'INR' : 'symbol' : '1.0-0'}} on this order
      </p>
    </mat-card>
    <p class="pt-3" style="color:gray;font-size:14px;"><span style="vertical-align: middle;"><mat-icon>verified_user</mat-icon></span> Safe & Secure
      Payment. Easy returns. 100% Authenticate products.</p>
      <!-- <button *ngIf="(items) != '' " routerLink="/placeorders"  mat-raised-button color="accent">PLACE ORDER</button> -->
      <div class="pb-3"></div>
  </div>
