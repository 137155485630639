import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserscartService } from 'src/app/userscart/userscart.service';
import { CartService } from '../cart.service';

export interface DialogData {
  index: number;
  itm: any;
}

@Component({
  selector: 'app-removecartitem',
  templateUrl: './removecartitem.component.html',
  styleUrls: ['./removecartitem.component.scss']
})
export class RemovecartitemComponent {
  cartSummary;
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
    private cartService: CartService,
    private _snackBar: MatSnackBar,
    private userCartService: UserscartService) { }


  removeItemfromCart() {
    this.cartService.deleteItem(this.data.index);  ////Remove item from cart array
    this.userCartService.deleteUsersCartwithGuid(this.data.itm.arrayguid).subscribe(data => {
    });
    this.cartSummary = this.cartService.calculateTotal();
    this._snackBar.open("✔ Successfully removed ' " + this.data.itm.product.name + " - " + this.data.itm.product.desc1 + " ' from your cart'", '',
      {
        duration: 3000,
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
  }
}
